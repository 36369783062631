import { useEffect } from 'react';


const RedirectToHtml = ({ target }) => {



const url = window.location.href;
const regex = /\?([^\/]+)/;
const match = url.match(regex);

if (match) {
  console.log(match[1]);  
  localStorage.setItem("referral_code" , match[1])
} else {
  console.log("No match found");
}


  useEffect(() => {
    // Redirect to the target HTML file
    window.location.replace('/Home.html');
    return null;
  }, []);

  return null;
};

export default RedirectToHtml;